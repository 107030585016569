<template>
  <div class="q-pa-md q-ma-md bg-purple text-white text-center text-h6">
    تولید کلیدهای سامانهٔ مؤدیان
  </div>
  <q-form ref="qform" @submit="onSubmit" greedy class="full-width">
    <q-card>
      <q-card-section>
        <div class="q-px-sm">نوع شخص:</div>
        <div class="q-gutter-sm">
          <q-option-group
            v-model="organizationType"
            :options="organizationTypes"
            inline
            color="primary"
          />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="q-px-sm">کد یا شناسهٔ ملی:</div>
        <q-input
          filled
          v-model="serialNumber"
          :label="determineSerialNumberLabel"
          lazy-rules
          input-style="text-align: left;direction: ltr"
          :rules="[
            (val) =>
              (val && val.length > 0) ||
              'لطفاً کد یا شناسهٔ ملی ۱۱ رقمی خود را وارد کنید',
          ]"
        />
      </q-card-section>
      <q-separator />
      <q-card-section v-if="organizationType != 'Unaffiliated'">
        <div class="q-px-sm">نام سازمان:</div>
        <q-input
          filled
          v-model="organizationName"
          label="مثال: پردازش موازی سامان"
          hint="دقیقاً مطابق با آگهی تأسیس شرکت"
          lazy-rules
          :rules="[
            (val) =>
              (val && val.length > 0) || 'لطفاً نام سازمان خود را وارد کنید',
          ]"
        />
      </q-card-section>
      <q-separator v-if="organizationType != 'Unaffiliated'" />
      <q-card-section v-if="organizationType == 'Unaffiliated'">
        <div class="q-px-sm">نام:</div>
        <q-input
          filled
          v-model="firstName"
          label="مثال: پژمان"
          lazy-rules
          :rules="[
            (val) => (val && val.length > 0) || 'لطفاً نام  خود را وارد کنید',
          ]"
        />
      </q-card-section>
      <q-separator v-if="organizationType == 'Unaffiliated'" />
      <q-card-section v-if="organizationType == 'Unaffiliated'">
        <div class="q-px-sm">نام خانوادگی:</div>
        <q-input
          filled
          v-model="surName"
          label="مثال: محمدی"
          lazy-rules
          :rules="[
            (val) =>
              (val && val.length > 0) || 'لطفاً نام خانوادگی خود را وارد کنید',
          ]"
        />
      </q-card-section>
      <q-separator v-if="organizationType == 'Unaffiliated'" />
      <q-card-section>
        <div class="q-px-sm">
          {{ determineOrganizationUnitOrNameFamilyInEnglishLabel }}
        </div>
        <q-input
          filled
          v-model="organizationUnitOrNameFamilyInEnglish"
          label="مثال: pardazeshmovazisaman"
          lazy-rules
          input-style="text-align: left;direction: ltr"
          :rules="[
            (val) =>
              (val && val.length > 0) || 'لطفاً نام را به انگلیسی وارد کنید',
          ]"
        />
        <div class="q-px-sm" style="font-size: smaller">
          بعضی از سایتها توصیه می‌کنند نام انگلیسی را بدون فاصله وارد کنید. در
          <a
            href="https://www.gica.ir/portal/APP_Client/UserFiles/document/CertProfileGuideForCSR.pdf"
            target="_blank"
            >مستند مرجع</a
          >
          در این مورد صحبتی نشده است.
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section v-if="organizationType == 'Unaffiliated'">
        <div class="q-px-sm">استان:</div>
        <q-select
          filled
          v-model="province"
          use-input
          input-debounce="0"
          label="استان"
          :options="filteredProvincs"
          @filter="filterProvince"
          behavior="dialog"
          clearable
          :rules="[
            (val) => (val && val.length > 0) || 'لطفاً استان را وارد کنید',
          ]"
        >
        </q-select>
      </q-card-section>
      <q-separator v-if="organizationType == 'Unaffiliated'" />
      <q-card-section v-if="organizationType == 'Unaffiliated'">
        <div class="q-px-sm">شهرستان:</div>
        <q-select
          filled
          v-model="city"
          use-input
          input-debounce="0"
          label="شهرستان"
          :options="filteredCities"
          @filter="filterCity"
          behavior="dialog"
          clearable
          :rules="[
            (val) => (val && val.length > 0) || 'لطفاً شهرستان را وارد کنید',
          ]"
        >
        </q-select>
      </q-card-section>
      <q-separator v-if="organizationType == 'Unaffiliated'" />
      <q-card-section>
        <div class="q-px-sm">ایمیل:</div>
        <q-input
          filled
          v-model="email"
          label="مثال: info@test.ir"
          lazy-rules
          input-style="text-align: left;direction: ltr"
          :rules="[
            (val) =>
              !val ||
              val.length == 0 ||
              isValidEmail(val) ||
              'ایمیل وارد شده صحیح نیست.',
          ]"
        />
        <div class="q-px-sm" style="font-size: smaller">
          ورود ایمیل طبق
          <a
            href="https://www.gica.ir/portal/APP_Client/UserFiles/document/CertProfileGuideForCSR.pdf"
            target="_blank"
            >مستند مرجع</a
          >
          الزامی نیست ولی اگر آن را خالی نگذارید معتبر بودن قالب ایمیل را بررسی
          می‌کنیم.
        </div>
      </q-card-section>
      <q-separator />
      <q-toggle v-model="optionalFields" label="نمایش ورودی‌های غیرالزامی" />
      <div class="q-px-sm" v-if="optionalFields" style="font-size: smaller">
        با توجه به
        <a
          href="https://www.gica.ir/portal/APP_Client/UserFiles/document/CertProfileGuideForCSR.pdf"
          target="_blank"
          >مستند مرجع</a
        >
        به نظر می‌رسد تمام ورودی‌های غیرالزامی برای اشخاص حقوقی کاربرد دارند و
        نمی‌بایست برای اشخاص حقیقی وارد شوند. با توجه به این که عموم ابزارهای
        آنلاین این اطلاعات را دریافت نمی‌کنند در صورتی که اطمینان ندارید آنها را
        پر نکنید.
      </div>
      <q-separator v-if="optionalFields" />
      <q-card-section v-if="optionalFields">
        <div class="q-px-sm">نام واحد سازمانی ۱:</div>
        <q-input filled v-model="organizationUnitLevel1" />
      </q-card-section>
      <q-separator v-if="optionalFields" />
      <q-card-section v-if="optionalFields">
        <div class="q-px-sm">نام واحد سازمانی ۲:</div>
        <q-input filled v-model="organizationUnitLevel2" />
      </q-card-section>
      <q-separator v-if="optionalFields" />
      <q-card-section v-if="optionalFields">
        <div class="q-px-sm">نام واحد سازمانی ۳:</div>
        <q-input filled v-model="organizationUnitLevel3" />
      </q-card-section>
      <q-separator v-if="optionalFields" />
      <q-card-section v-if="optionalFields">
        <div class="q-px-sm">نقش یا سمت متقاضی در سازمان:</div>
        <q-input filled v-model="role" label="مثال: مدیر عامل" />
        <div class="q-px-sm" v-if="optionalFields" style="font-size: smaller">
          با توجه به
          <a
            href="https://www.gica.ir/portal/APP_Client/UserFiles/document/CertProfileGuideForCSR.pdf"
            target="_blank"
            >مستند مرجع</a
          >
          این مورد برای اشخاص حقوقی الزامی است منتهی الزامی بودن آن در فهرست
          ورودی‌های اشخاص حقیقی ذکر شده است. عموم سایتهای تولید درخواست یا آن را
          ندارند یا در صورت دریافتِ ورودیی با این نام، آن را به درستی در درخواست
          نهایی نمی‌گنجانند و از آن استفاده نمی‌کنند!
        </div>
      </q-card-section>
    </q-card>

    <div class="full-width q-ma-md q-pa-md">
      <div
        v-if="validationErrors"
        q-ma-md
        style="color: red; text-align: center"
      >
        لطفاً ورودی‌ها را بررسی و اشکالات را رفع کنید.
      </div>
      <q-btn
        class="full-width"
        label="تولید کلیدهای سامانهٔ مؤدیان"
        type="button"
        @click="onSubmit"
        :color="submitButtonColor"
      />
    </div>
  </q-form>
  <q-dialog
    v-model="generatedkeys"
    persistent
    maximized
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card>
      <q-bar>
        <div class="text-h6">کلیدهای تولید شده</div>
        <q-space />
        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-white text-primary">بستن</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section class="q-pt-none row">
        <div class="col">
          <q-card>
            <q-card-section style="text-align: center"
              >کلید عمومی جهت کارپوشه</q-card-section
            >
            <q-card-section>
              <q-input
                v-model="publicKey"
                filled
                rows="25"
                readonly
                input-style="text-align: left;direction:ltr"
                type="textarea"
              />
            </q-card-section>
            <q-card-section>
              <div class="full-width">
                <q-btn
                  class="full-width"
                  label="دریافت کلید عمومی"
                  color="primary"
                  @click="downloadFile(publicKey, 'public.txt')"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col">
          <q-card>
            <q-card-section style="text-align: center"
              >کلید خصوصی جهت نرم‌افزار</q-card-section
            >
            <q-card-section>
              <q-input
                v-model="privateKey"
                filled
                rows="25"
                readonly
                input-style="text-align: left;direction:ltr"
                type="textarea"
              />
            </q-card-section>
            <q-card-section>
              <div class="full-width">
                <q-btn
                  class="full-width"
                  label="دریافت کلید خصوصی"
                  color="primary"
                  @click="downloadFile(privateKey, 'private.txt')"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col">
          <q-card>
            <q-card-section style="text-align: center"
              >CSR جهت درخواست گواهی امضای الکترونیکی</q-card-section
            >
            <q-card-section>
              <q-input
                v-model="certificateSignReq"
                rows="25"
                readonly
                input-style="text-align: left;direction:ltr"
                filled
                type="textarea"
              />
            </q-card-section>
            <q-card-section>
              <div class="full-width">
                <q-btn
                  class="full-width"
                  label="دریافت درخواست گواهی"
                  color="primary"
                  @click="downloadFile(certificateSignReq, 'csr.txt')"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-card-section>
      <q-card-section style="text-align: center; background-color: gray">
        <q-icon name="warning" color="orange" size="32px" />
        هر سه فایل ساخته شده می‌بایست دریافت و در محل امنی نگهداری شود.
      </q-card-section>
    </q-card>
  </q-dialog>

  <div class="q-pa-md q-ma-md bg-purple text-white text-center text-h6">
    بررسی کلیدها
  </div>
  <q-form @submit="onVerify" class="full-width">
    <q-card>
      <q-card-section class="q-pa-md q-ma-md text-center">
        <q-icon name="information" color="blue" size="32px" />
        می‌توانید با انتخاب یا کپی حداقل دو کلید از سه کلید موجود صحت و ارتباط
        آنها را اعتبارسنجی کنید.
      </q-card-section>
      <q-card-section class="q-pt-none row">
        <div class="col">
          <q-card>
            <q-card-section>
              <div class="full-width">
                <q-btn
                  class="full-width"
                  label="انتخاب کلید عمومی (public)"
                  color="primary"
                  @click="pickFileFor('publicKey')"
                />
              </div>
            </q-card-section>
            <q-card-section>
              <q-input
                v-model="publicKey"
                filled
                rows="25"
                input-style="text-align: left;direction:ltr"
                spellcheck="false"
                type="textarea"
              />
            </q-card-section>
            <q-card-section v-if="publicKey != ''">
              <div class="full-width">
                <q-btn
                  class="full-width q-ma-xs"
                  label="دریافت کلید عمومی"
                  color="primary"
                  @click="downloadFile(publicKey, 'public.txt')"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col">
          <q-card>
            <q-card-section>
              <div class="full-width">
                <q-btn
                  class="full-width"
                  label="انتخاب کلید خصوصی (private)"
                  color="primary"
                  @click="pickFileFor('privateKey')"
                />
              </div>
            </q-card-section>
            <q-card-section>
              <q-input
                v-model="privateKey"
                filled
                rows="25"
                input-style="text-align: left;direction:ltr"
                spellcheck="false"
                type="textarea"
              />
            </q-card-section>
            <q-card-section v-if="privateKey != ''">
              <div class="full-width">
                <q-btn
                  class="full-width q-ma-xs"
                  label="دریافت کلید خصوصی"
                  color="primary"
                  @click="downloadFile(privateKey, 'private.txt')"
                />
                <q-btn
                  class="full-width q-ma-xs"
                  label="استخراج کلید عمومی"
                  color="primary"
                  @click="extractPublicKeyFromPrivate()"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col">
          <q-card>
            <q-card-section>
              <div class="full-width">
                <q-btn
                  class="full-width"
                  label="انتخاب درخواست گواهی (CSR)"
                  color="primary"
                  @click="pickFileFor('certificateSignReq')"
                />
              </div>
            </q-card-section>
            <q-card-section>
              <q-input
                v-model="certificateSignReq"
                rows="25"
                input-style="text-align: left;direction:ltr"
                filled
                spellcheck="false"
                type="textarea"
              />
            </q-card-section>
            <q-card-section v-if="certificateSignReq != ''">
              <div class="full-width">
                <q-btn
                  class="full-width q-ma-xs"
                  label="دریافت درخواست گواهی"
                  color="primary"
                  @click="downloadFile(certificateSignReq, 'csr.txt')"
                />
                <q-btn
                  class="full-width q-ma-xs"
                  label="استخراج کلید عمومی"
                  color="primary"
                  @click="extractPublicKeyFromCSR()"
                />
                <q-btn
                  class="full-width q-ma-xs"
                  label="مشاهدهٔ محتوا"
                  color="primary"
                  @click="viewCSR()"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-card-section>
      <div class="full-width q-ma-md q-pa-md">
        <div v-if="verifyFailed" q-ma-md style="color: red; text-align: center">
          {{ verifyErrors }}
        </div>
        <q-btn
          class="full-width"
          label="بررسی صحت و اعتبار کلیدها"
          type="button"
          @click="onVerify"
          :color="verifyButtonColor"
        />
      </div>
    </q-card>
  </q-form>

  <div class="q-pa-md q-ma-md bg-purple text-white text-center text-h6">
    گواهی امضا
  </div>
  <q-form @submit="onVerify" class="full-width">
    <q-card>
      <q-card-section class="q-pa-md q-ma-md text-center">
        <q-icon name="information" color="blue" size="32px" />
        می‌توانید محتوای گواهی امضا را ببینید یا کلید عمومی متناظر آن را استخراج
        کنید.
        <div class="full-width q-ma-md q-pa-md">
          <div
            v-if="extractFailed"
            q-ma-md
            style="color: red; text-align: center"
          >
            {{ extractErrors }}
          </div>
          <q-btn
            class="full-width"
            label="انتخاب گواهی امضا (cer)"
            type="button"
            @click="pickFileFor('cer')"
            :color="extractButtonColor"
          />
          <q-btn
            class="full-width q-ma-md"
            label="استخراج کلید عمومی"
            type="button"
            @click="extractPublicKeyFromCER"
            :color="extractButtonColor"
            v-if="cer != ''"
          />
          <q-btn
            v-if="cer != ''"
            class="full-width"
            label="مشاهدهٔ محتوا"
            color="primary"
            @click="viewCER()"
          />
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none row" v-if="cer != ''">
        <div class="col">
          <q-card>
            <q-card-section style="text-align: center"
              >گواهی امضا (CER)</q-card-section
            >
            <q-card-section>
              <q-input
                v-model="cer"
                filled
                rows="25"
                input-style="text-align: left;direction:ltr"
                spellcheck="false"
                type="textarea"
              />
            </q-card-section>
          </q-card>
        </div>
        <div class="col" v-if="publicKey != ''">
          <q-card>
            <q-card-section style="text-align: center"
              >کلید عمومی جهت کارپوشه</q-card-section
            >
            <q-card-section>
              <q-input
                v-model="publicKey"
                filled
                rows="25"
                readonly
                input-style="text-align: left;direction:ltr"
                spellcheck="false"
                type="textarea"
              />
            </q-card-section>
            <q-card-section v-if="publicKey != ''">
              <div class="full-width">
                <q-btn
                  class="full-width q-ma-xs"
                  label="دریافت کلید عمومی"
                  color="primary"
                  @click="downloadFile(publicKey, 'public.txt')"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-card-section>
    </q-card>
  </q-form>

  <div class="q-pa-md q-ma-md bg-purple text-white text-center text-h6">
    پرسش‌های پرتکرار
  </div>
  <q-card class="q-ma-md">
    <q-card-section class="bg-blue text-white">
      <div class="text-h6">این ابزار به چه دردی می‌خورد؟</div>
    </q-card-section>
    <q-card-section class="q-pt-none"
      >سامانهٔ مؤدیان مالیاتی سامانه‌ای متعلق به ادارهٔ امور مالیاتی کشور است که
      طبق قانون، مؤدیان مکلفند فاکتورهای فروش و اطلاعات مرتبط با آنها را در آن
      بارگذاری کنند (اطلاعات بیشتر را در
      <a
        href="https://www.intamedia.ir/Law-of-store-terminals-and-taxpayer-system"
        target="_blank"
        >این قسمت از سایت سازمان امور مالیاتی کشور</a
      >
      مطالعه بفرمایید). برای ارسال اطلاعات به این سامانه لازم است مؤدیان امضای
      دیجیتال دریافت کنند و کلیدهای دریافت شده را جهت امضای اطلاعات ارسال
      استفاده کنند. کاربرانی که از نرم‌افزارهای واسط استفاده می‌کنند لازم است
      کلید خصوصی دریافت شده را در آن نرم‌افزار و کلید عمومی را در کارپوشهٔ
      سازمان مالیاتی وارد کنند. بسته به نرم‌افزار و روش کار مؤدی ممکن است لازم
      باشد گواهی امضای دریافت شده نیز در نرم‌افزار وارد شود. بخش ابتدایی دریافت
      کلیدها، تولید آنها روی کامپیوتر است که می‌بایست طبق
      <a
        href="https://www.gica.ir/portal/APP_Client/UserFiles/document/CertProfileGuideForCSR.pdf"
        target="_blank"
        >این مستند</a
      >
      انجام شود. از آنجا که استفاده از ابزار خط فرمان openssl و تهیهٔ فایل
      پیکربندی ورودی برای آن که در آن مستند توضیح داده شده کار فنی نسبتاً
      پیچیده‌ای است استفاده از ابزاری مانند ابزار حاضر می‌تواند به کاهش
      پیچیدگی‌های فنی این فرایند کمک کند. علاوه بر آن این ابزار امکانات اضافی
      مفید دیگری برای مشاهدهٔ محتوای کلیدها و همینطور استخراج کلید عمومی از سایر
      کلیدها فراهم می‌آورد. توجه فرمایید که برای تولید کلید، روش توصیه شده
      استفاده از ابزار آنلاین ارائه شده توسط خود
      <a href="https://www.gica.ir" target="_blank"
        >مرکز صدور گواهی الکترونیکی میانی عام</a
      >
      است که مسئولیت صدور گواهی امضای دیجیتال نهایی مورد نیاز را نیز به عهده
      دارد.
    </q-card-section>
  </q-card>
  <q-card class="q-ma-md">
    <q-card-section class="bg-blue text-white">
      <div class="text-h6">
        من از نرم‌افزار تدبیر تولید شده توسط شرکت پردازش موازی سامان استفاده
        می‌کنم. چطور می‌توانم اطلاعاتم را به سامانهٔ مؤدیان ارسال کنم؟
      </div>
    </q-card-section>
    <q-card-section class="q-pt-none"
      >برای این کار می‌بایست از سامانهٔ مؤدیان تدبیر استفاده کنید. برای آشنایی
      با آن
      <a
        href="https://www.sppcco.com/%d8%b3%d8%a7%d9%85%d8%a7%d9%86%d9%87-%d9%85%d9%88%d8%af%db%8c%d8%a7%d9%86-%d8%aa%d8%af%d8%a8%db%8c%d8%b1/"
        target="_blank"
        >اینجا</a
      >
      را ببینید.</q-card-section
    >
  </q-card>
  <q-card class="q-ma-md">
    <q-card-section class="bg-blue text-white">
      <div class="text-h6">آیا اطلاعات وارد شده در این سایت ذخیره می‌شود؟</div>
    </q-card-section>
    <q-card-section class="q-pt-none"
      >خیر، هیچ اطلاعاتی در این سایت ذخیره نمی‌شود.</q-card-section
    >
  </q-card>
  <q-card class="q-ma-md">
    <q-card-section class="bg-blue text-white">
      <div class="text-h6">
        آیا با ورودی‌های یکسان کلیدهای یکسانی ایجاد می‌شود؟
      </div>
    </q-card-section>
    <q-card-section class="q-pt-none"
      >خیر، کلیدهای تولید شده وابسته به کلید خصوصی هستند که به ورودی‌ها مرتبط
      نیست و به صورت تصادفی تولید می‌شود. از این جهت نه خود شما و نه کسی که
      اطلاعات شما را دارد نمی‌تواند کلیدی همسان با کلیدی که پیشتر تولید شده
      ایجاد نماید.</q-card-section
    >
  </q-card>
  <q-card class="q-ma-md">
    <q-card-section class="bg-blue text-white">
      <div class="text-h6">
        بعضی از ابزارهای تولید کلید شماره تلفن دریافت می‌کنند. آیا لازم نیست
        شماره تلفن نیز وارد شود؟
      </div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      خیر، در
      <a
        href="https://www.gica.ir/portal/APP_Client/UserFiles/document/CertProfileGuideForCSR.pdf"
        target="_blank"
        >مستند مرجع</a
      >
      شماره تلفن خواسته نشده و طی بررسی‌های به عمل آمده ابزارهایی که چنین
      اطلاعاتی را دریافت می‌کنند آن را در درخواست گواهی نمی‌گنجانند و احتمالاً
      صرفاً از آن به منظور بازاریابی استفاده می‌کنند.
    </q-card-section>
  </q-card>
  <q-card class="q-ma-md">
    <q-card-section class="bg-blue text-white">
      <div class="text-h6">
        ابزار بررسی صحت کلیدهای دیگر صحت کلیدهای من را تأیید نمی‌کند اما ابزار
        شما آن را تأیید می‌کند. مشکل کجاست؟
      </div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      طی بررسی به عمل آمده، مشکل ابزار بررسی صحت کلید مد نظر شما آن است که
      کلیدها را به درستی بارگذاری نمی‌کند. اگر ابزار ما کلیدهای شما تأیید می‌کند
      و می‌توانید محتوای درخواست گواهی امضا را با استفاده از آن به درستی مشاهده
      کنید کلیدهای شما مشکلی ندارند.
    </q-card-section>
  </q-card>
  <q-dialog v-model="alertDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ alertTitle }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ alertContents }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="تأیید" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style></style>

<script>
import { ref } from "vue";
import { Loading } from "quasar";
import provinceModels from "../json/provinces.json";
import cityModels from "../json/cities.json";
export default {
  name: "GenerateCSR",
  computed: {
    determineSerialNumberLabel() {
      return this.organizationType == "Unaffiliated" ? "کد ملی" : "شناسهٔ ملی";
    },
    determineOrganizationUnitOrNameFamilyInEnglishLabel() {
      return this.organizationType == "Unaffiliated"
        ? "نام و نام خانوادگی به انگلیسی:"
        : "نام سازمان به انگلیسی:";
    },
  },
  setup() {
    const GOVERNMENTAL = "Governmental";
    const NONGOVERNMENTAL = "Non-Governmental";
    const UNAFFILIATED = "Unaffiliated";

    const organizationType = ref(NONGOVERNMENTAL);
    const serialNumber = ref("");
    const organizationName = ref("");
    const organizationUnitOrNameFamilyInEnglish = ref("");
    const givenName = ref("");
    const surname = ref("");
    const province = ref("");
    const city = ref("");
    const email = ref("");
    const organizationUnitLevel1 = ref("");
    const organizationUnitLevel2 = ref("");
    const organizationUnitLevel3 = ref("");
    const role = ref("");

    const optionalFields = ref(false);

    const provinces = provinceModels.map((o) => o.name);
    const filteredProvincs = ref(provinces);

    const cities = cityModels.map((o) => o.name);
    const filteredCities = ref(cities);

    const publicKey = ref("");
    const privateKey = ref("");
    const certificateSignReq = ref("");
    const cer = ref("");

    const validationErrors = ref(false);

    const submitButtonColor = ref("primary");
    const generatedkeys = ref(false);
    const qform = ref(null);

    const verifyButtonColor = ref("primary");
    const verifyFailed = ref(false);
    const verifyErrors = ref("");

    const extractButtonColor = ref("primary");
    const extractFailed = ref(false);
    const extractErrors = ref("");

    const alertDialog = ref(false);
    const alertTitle = ref("");
    const alertContents = ref("");

    return {
      organizationType,
      serialNumber,
      organizationName,
      organizationUnitOrNameFamilyInEnglish,
      firstName: givenName,
      surName: surname,
      province,
      city,
      email,
      organizationUnitLevel1,
      organizationUnitLevel2,
      organizationUnitLevel3,
      role,
      optionalFields,
      publicKey,
      privateKey,
      certificateSignReq,
      cer,
      organizationTypes: [
        {
          label: "حقیقی",
          value: UNAFFILIATED,
        },
        {
          label: "حقوقی دولتی",
          value: GOVERNMENTAL,
        },
        {
          label: "حقوقی غیردولتی",
          value: NONGOVERNMENTAL,
        },
      ],
      provinces,
      filteredProvincs,
      cities,
      filteredCities,

      validationErrors,
      submitButtonColor,
      generatedkeys,
      qform,

      verifyButtonColor,
      verifyFailed,
      verifyErrors,

      extractButtonColor,
      extractFailed,
      extractErrors,

      alertDialog,
      alertTitle,
      alertContents,
      async onSubmit() {
        validationErrors.value = false;
        if ((await qform.value.validate()) == false) {
          validationErrors.value = true;
          submitButtonColor.value = "red";
          return;
        }
        submitButtonColor.value = "primary";
        Loading.show();
        const URL = `https://api.moaddiyan.com/api/csr`;
        const response = await fetch(URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            countryCode: "IR",
            organizationType: organizationType.value,
            organizationName:
              organizationType.value != "Unaffiliated"
                ? organizationName.value
                : "",
            givenName: givenName.value,
            surName: surname.value,
            organizationUnitOrNameFamilyInEnglish:
              organizationUnitOrNameFamilyInEnglish.value,
            serialNumber: serialNumber.value,
            province: province.value,
            city: city.value,
            email: email.value,
            organizationUnitLevel1: organizationUnitLevel1.value,
            organizationUnitLevel2: organizationUnitLevel2.value,
            organizationUnitLevel3: organizationUnitLevel3.value,
            role: role.value,
          }),
        });
        Loading.hide();

        if (!response.ok) {
          alertTitle.value = "خطا";
          alertContents.value = await response.json();
          alertDialog.value = true;
          return;
        }
        var res = await response.json();
        publicKey.value = res.publicKey;
        privateKey.value = res.privateKey;
        certificateSignReq.value = res.certificateSignReq;
        generatedkeys.value = true;
      },
      downloadFile(content, fileName) {
        const link = document.createElement("a");
        const file = new Blob([content], { type: "text/plain" });
        link.href = URL.createObjectURL(file);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      },
      isValidEmail(email) {
        const regex = /^[A-Za-z0-9+_.-]+@(.+)$/;
        return regex.test(email);
      },
      filterProvince(val, update) {
        if (val === "") {
          update(() => {
            filteredProvincs.value = provinces;
          });
          return;
        }

        update(() => {
          const needle = val.toLowerCase();
          filteredProvincs.value = provinces.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
        });
      },
      filterCity(val, update) {
        if (val === "") {
          update(() => {
            filteredCities.value = cities;
          });
          return;
        }

        update(() => {
          const needle = val.toLowerCase();
          filteredCities.value = cities.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
        });
      },
      pickFileFor(target) {
        var input = document.createElement("input");
        input.type = "file";

        input.onchange = (e) => {
          var file = e.target.files[0];
          var reader = new FileReader();
          reader.readAsText(file, "UTF-8");
          reader.onload = (readerEvent) => {
            var content = readerEvent.target.result;
            switch (target) {
              case "publicKey":
                publicKey.value = content;
                break;
              case "privateKey":
                privateKey.value = content;
                break;
              case "certificateSignReq":
                certificateSignReq.value = content;
                break;
              case "cer":
                cer.value = content;
                break;
            }
          };
        };

        input.click();
      },
      async onVerify() {
        verifyFailed.value = false;
        verifyErrors.value = "";
        if (
          (publicKey.value == "" && privateKey.value == "") ||
          (certificateSignReq.value == "" && privateKey.value == "") ||
          (certificateSignReq.value == "" && publicKey.value == "")
        ) {
          verifyErrors.value =
            "لطفاً مقادیر حداقل دو کلید را جهت بررسی وارد کنید.";
          verifyButtonColor.value = "red";
          verifyFailed.value = true;
          return;
        }
        verifyButtonColor.value = "primary";
        Loading.show();
        const URL = `https://api.moaddiyan.com/api/csr/verify/keys`;
        var formdata = new FormData();
        if (privateKey.value != "") {
          var strblob1 = new Blob([privateKey.value], { type: "text/plain" });
          formdata.append("privateKey", strblob1, "privateKey.txt");
        }
        if (publicKey.value != "") {
          var strblob2 = new Blob([publicKey.value], { type: "text/plain" });
          formdata.append("publicKey", strblob2, "publicKey.txt");
        }
        if (certificateSignReq.value != "") {
          var strblob3 = new Blob([certificateSignReq.value], {
            type: "text/plain",
          });
          formdata.append("csr", strblob3, "csr.txt");
        }

        const response = await fetch(URL, {
          method: "POST",
          body: formdata,
        });
        Loading.hide();

        if (!response.ok) {
          alertTitle.value = "خطا";
          alertContents.value = await response.json();
          alertDialog.value = true;
          return;
        }
        alertTitle.value = "اعلان";
        alertContents.value = "کلیدهای وارد شده همخوان و صحیح هستند.";
        alertDialog.value = true;
      },
      async extractPublicKeyFromCSR() {
        verifyFailed.value = false;
        verifyErrors.value = "";
        if (certificateSignReq.value == "") {
          verifyErrors.value =
            "لطفاً مقدار کلید درخواست گواهی را جهت استخراج کلید عمومی از آن وارد کنید.";
          verifyButtonColor.value = "red";
          verifyFailed.value = true;
          return;
        }
        verifyButtonColor.value = "primary";
        Loading.show();
        const URL = `https://api.moaddiyan.com/api/csr/public`;
        var formdata = new FormData();
        if (certificateSignReq.value != "") {
          var strblob3 = new Blob([certificateSignReq.value], {
            type: "text/plain",
          });
          formdata.append("file", strblob3, "csr.txt");
        }

        const response = await fetch(URL, {
          method: "POST",
          body: formdata,
        });
        Loading.hide();

        if (!response.ok) {
          alertTitle.value = "خطا";
          alertContents.value = await response.json();
          alertDialog.value = true;
          return;
        }
        publicKey.value = await response.json();
        alertTitle.value = "اعلان";
        alertContents.value =
          "انجام شد. محتوای کلید عمومی را در کادر مربوطه می‌بینید و می‌توانید آن را از طریق دکمهٔ پایین آن دریافت کنید.";
        alertDialog.value = true;
      },
      async extractPublicKeyFromPrivate() {
        verifyFailed.value = false;
        verifyErrors.value = "";
        if (privateKey.value == "") {
          verifyErrors.value =
            "لطفاً مقدار کلید درخواست گواهی را جهت استخراج کلید عمومی از آن وارد کنید.";
          verifyButtonColor.value = "red";
          verifyFailed.value = true;
          return;
        }
        verifyButtonColor.value = "primary";
        Loading.show();
        const URL = `https://api.moaddiyan.com/api/csr/public/from/private`;
        var formdata = new FormData();
        if (privateKey.value != "") {
          var strblob3 = new Blob([privateKey.value], {
            type: "text/plain",
          });
          formdata.append("file", strblob3, "private.txt");
        }

        const response = await fetch(URL, {
          method: "POST",
          body: formdata,
        });
        Loading.hide();

        if (!response.ok) {
          alertTitle.value = "خطا";
          alertContents.value = await response.json();
          alertDialog.value = true;
          return;
        }
        publicKey.value = await response.json();
        alertTitle.value = "اعلان";
        alertContents.value =
          "انجام شد. محتوای کلید عمومی را در کادر مربوطه می‌بینید و می‌توانید آن را از طریق دکمهٔ پایین آن دریافت کنید.";
        alertDialog.value = true;
      },
      async viewCSR() {
        verifyFailed.value = false;
        verifyErrors.value = "";
        if (certificateSignReq.value == "") {
          verifyErrors.value =
            "لطفاً مقدار کلید درخواست گواهی را جهت مشاهدهٔ محتوای آن وارد کنید.";
          verifyButtonColor.value = "red";
          verifyFailed.value = true;
          return;
        }
        verifyButtonColor.value = "primary";
        Loading.show();
        const URL = `https://api.moaddiyan.com/api/csr/view`;
        var formdata = new FormData();
        if (certificateSignReq.value != "") {
          var strblob3 = new Blob([certificateSignReq.value], {
            type: "text/plain",
          });
          formdata.append("file", strblob3, "csr.txt");
        }

        const response = await fetch(URL, {
          method: "POST",
          body: formdata,
        });
        Loading.hide();

        if (!response.ok) {
          alertTitle.value = "خطا";
          alertContents.value = await response.json();
          alertDialog.value = true;
          return;
        }
        var res = await response.json();
        organizationType.value = res.request.organizationType;
        organizationName.value = res.request.organizationName;
        givenName.value = res.request.givenName;
        surname.value = res.request.surname;
        organizationUnitOrNameFamilyInEnglish.value =
          res.request.organizationUnitOrNameFamilyInEnglish;
        serialNumber.value = res.request.serialNumber;
        province.value = res.request.province;
        city.value = res.request.city;
        email.value = res.request.email;
        organizationUnitLevel1.value = res.request.organizationUnitLevel1;
        organizationUnitLevel2.value = res.request.organizationUnitLevel2;
        organizationUnitLevel3.value = res.request.organizationUnitLevel3;
        role.value = res.request.role;
        optionalFields.value =
          organizationUnitLevel1.value != "" ||
          organizationUnitLevel2.value != "" ||
          organizationUnitLevel3.value != "" ||
          role.value != "";
        alertTitle.value = "مقادیر ورودی‌ها مطابق این محتوا پر شد.";
        alertContents.value = res.subjectName;
        alertDialog.value = true;
      },
      async extractPublicKeyFromCER() {
        extractFailed.value = false;
        extractErrors.value = "";
        if (cer.value == "") {
          verifyErrors.value =
            "لطفاً مقدار گواهی را جهت استخراج کلید عمومی از آن وارد کنید.";
          verifyButtonColor.value = "red";
          extractFailed.value = true;
          return;
        }
        extractButtonColor.value = "primary";
        Loading.show();
        const URL = `https://api.moaddiyan.com/api/csr/public/from/cer`;
        var formdata = new FormData();
        if (cer.value != "") {
          var strblob3 = new Blob([cer.value], {
            type: "text/plain",
          });
          formdata.append("file", strblob3, "cer.txt");
        }

        const response = await fetch(URL, {
          method: "POST",
          body: formdata,
        });
        Loading.hide();

        if (!response.ok) {
          alertTitle.value = "خطا";
          alertContents.value = await response.json();
          alertDialog.value = true;
          return;
        }
        publicKey.value = await response.json();
        alertTitle.value = "اعلان";
        alertContents.value =
          "انجام شد. محتوای کلید عمومی را در کادر مربوطه می‌بینید و می‌توانید آن را از طریق دکمهٔ پایین آن دریافت کنید.";
        alertDialog.value = true;
      },
      async viewCER() {
        extractFailed.value = false;
        extractErrors.value = "";
        if (cer.value == "") {
          extractErrors.value =
            "لطفاً مقدار کلید گواهی را جهت مشاهدهٔ محتوای آن وارد کنید.";
          extractButtonColor.value = "red";
          extractFailed.value = true;
          return;
        }
        extractButtonColor.value = "primary";
        Loading.show();
        const URL = `https://api.moaddiyan.com/api/csr/view/cer`;
        var formdata = new FormData();
        if (cer.value != "") {
          var strblob3 = new Blob([cer.value], {
            type: "text/plain",
          });
          formdata.append("file", strblob3, "cer.txt");
        }

        const response = await fetch(URL, {
          method: "POST",
          body: formdata,
        });
        Loading.hide();

        if (!response.ok) {
          alertTitle.value = "خطا";
          alertContents.value = await response.json();
          alertDialog.value = true;
          return;
        }
        var res = await response.json();
        organizationType.value = res.request.organizationType;
        organizationName.value = res.request.organizationName;
        givenName.value = res.request.givenName;
        surname.value = res.request.surname;
        organizationUnitOrNameFamilyInEnglish.value =
          res.request.organizationUnitOrNameFamilyInEnglish;
        serialNumber.value = res.request.serialNumber;
        province.value = res.request.province;
        city.value = res.request.city;
        email.value = res.request.email;
        organizationUnitLevel1.value = res.request.organizationUnitLevel1;
        organizationUnitLevel2.value = res.request.organizationUnitLevel2;
        organizationUnitLevel3.value = res.request.organizationUnitLevel3;
        role.value = res.request.role;
        optionalFields.value =
          organizationUnitLevel1.value != "" ||
          organizationUnitLevel2.value != "" ||
          organizationUnitLevel3.value != "" ||
          role.value != "";
        alertTitle.value = "مقادیر ورودی‌ها مطابق این محتوا پر شد.";
        alertContents.value = res.subjectName;
        alertDialog.value = true;
      },
    };
  },
};
</script>
<style>
.lft {
  direction: ltr;
  text-align: left;
}
</style>
