
import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Loading
  }
}