import './assets/Vazirmatn-UI-FD-font-face.css'
import './assets/base.css'
import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

const app = createApp(App)
app.use(Quasar, quasarUserOptions)
app.mount('#app')


