<template>
  <q-layout>
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-toolbar-title> ابزارهای سامانهٔ مؤدیان تدبیر </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <GenerateCSR />
    </q-page-container>
  </q-layout>
</template>

<script>
import GenerateCSR from "./components/GenerateCSR.vue";
export default {
  name: "App",

  components: { GenerateCSR },

  setup() {
    document.title = "ابزارهای سامانهٔ مؤدیان تدبیر";
    return {};
  },
};
</script>
